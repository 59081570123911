<template>
  <div class="container">
    <div class="newList" v-if="newsList.length > 0">
      <!--        新闻列表-->
      放映员风采
      <div
        class="newList-item"
        v-for="(news, index) in newsList"
        :key="index"
        @click="toDetail(news.id)"
      >
        <div class="newList-item_title">
          <p>{{ news.title }}</p>
        </div>
        <div class="newList-item_thumb">
          <!--          <img  v-lazy="news.thumb"  alt="">-->
          <van-image
            fit="cover"
            style="width: 100%; height: 2rem; border-radius: 4px"
            lazy-load
            :src="news.thumb"
          ></van-image>
        </div>
      </div>
    </div>
    <div v-else class="newsListEmpty"></div>
  </div>
</template>

<script>
export default {
  name: 'projectionists',
  data() {
    return {
      title: '投影师',
      newsList: [],
    }
  },
  methods: {
    // 跳转到明细页面
    toDetail(id) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.container {
  padding: 0;
}
.newList {
  padding: 10px;
  background-color: #fff;
  &-item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
    min-height: 120px;
    &_title {
      color: #000;
      flex: 2;
      //padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > p {
        //三行省略
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 60px;
        font-size: 15px;
        margin: 0;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }
    &_thumb {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      //img{
      //  width: 120px;
      //  height: 90px;
      //  border-radius:4px;
      //}
    }
  }
}
.newsListEmpty {
  width: 100%;
  height: 50vh;
  background: url('../../../assets/illustrations/Empty.svg') no-repeat center;
  background-size: 50%;
  text-align: center;
  &:after {
    display: block;
    content: '暂无内容';
    transform: translateY(300px);
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 20px;
  }
}
</style>
